@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.list-page {
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 4px 9px 0 rgba(28,50,89,0.03);
    border: 1px solid rgba(28,50,89,0.06);
}

.filter-field[data-source="text"],
.filter-field[data-source="interestIds"],
.filter-field[data-source="label"],
.filter-field[data-source="title"] {
    width: 100%;
}

.filter-field[data-source="email"],
.filter-field[data-source="phoneNumber"],
.filter-field[data-source="userId"],
.filter-field[data-source="promotionId"] {
    width: 50%;
}

.filter-field[data-source="gender"],
.filter-field[data-source="countryId"],
.filter-field[data-source="interestId"],
.filter-field[data-source="authorId"],
.filter-field[data-source="role"] {
    flex: 2 1;
}

.filter-field[data-source="ageFrom"],
.filter-field[data-source="ageTo"] {
    flex: 1 1;
}

.MuiFormControl-root {
    width: 50%;
}
[class^="makeStyles-main"] {
    background-image: url(/static/media/bg.c5bcae01.jpg);
}

.MuiToolbar-root form {
    flex: 1 1;
}
.MuiButtonBase-root.MuiButton-root.page-number {
    min-width: 30px;
}

.MuiTablePagination-toolbar span {
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.MuiTableCell-head span {
    white-space: normal !important;
}
